<template>
    <Carousel :itemsToShow="visibleSlides" :wrapAround="true" ref="carousel">
        <template #slides>
            <Slide v-for="(project, index) in projects" :key="index">
                <div class="carousel__item no-markup-text">
                    <div class="project-carousel__img-container" @click="handleSlideNavigation(project.id)">
                        <img
                            class="project-carousel__img"
                            :src="getImgPath('projects', project.img_names[0])"
                            :alt="project.title"
                            draggable="false"
                        />
                        <div class="project-carousel__img-gradient">
                            <div>
                                <h3 v-html="project.title" />
                                <div class="project-carousel__caption">
                                    <span>{{ project.tools.join(" • ") }}</span>
                                    <div
                                        class="project-carousel__share-button"
                                        @click.stop="shareProject(project, project.id)"
                                    >
                                        <ios-link-icon w="20px" h="20px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a class="project-carousel__slide-link" :href="project.project_raw_link" target="_blank">
                        {{ project.project_link }}
                    </a>
                </div>
            </Slide>
        </template>
        <template #addons>
            <navigation />
            <pagination />
        </template>
    </Carousel>
</template>

<script>
import projectsDe from "../assets/texts/projects_de.json";
import projectsEn from "../assets/texts/projects_en.json";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import IosLinkIcon from "vue-ionicons/dist/ios-link.vue";
export default {
    name: "ProjectCarousel",
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        IosLinkIcon,
    },
    data() {
        return {
            projects: [],
            windowWidth: window.innerWidth,
        };
    },
    watch: {
        lang(selected) {
            this.projects = selected === "de" ? projectsDe : projectsEn;
        },
    },
    mounted() {
        this.projects = this.lang === "de" ? projectsDe : projectsEn;
        this.$nextTick(() => {
            window.addEventListener("resize", this.onResize);
        });
    },
    beforeUnmounted() {
        window.removeEventListener("resize", this.onResize);
    },
    computed: {
        visibleSlides() {
            if (this.windowWidth < 450) {
                return 1.4;
            }
            if (this.windowWidth <= 675) {
                return 1.6;
            }
            if (this.windowWidth <= 900) {
                return 1.7;
            }
            return 1.5;
        },
        lang() {
            return this.$store.state.lang;
        },
    },
    methods: {
        getShortDescr(description) {
            // shorten the length of the description text according to the width of the window
            let descrLength = 150;
            switch (true) {
                case this.windowWidth > 1000:
                    descrLength = 150;
                    break;
                case this.windowWidth > 750:
                    descrLength = 100;
                    break;
                case this.windowWidth > 600:
                    descrLength = 300;
                    break;
                case this.windowWidth > 420:
                    descrLength = 200;
                    break;
            }
            return description.substr(0, descrLength) + "\u2026";
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        handleSlideNavigation(index) {
            this.$router.push({ name: "Projektdetails", params: { id: index } });
        },
    },
};
</script>

<style scoped>
.project-carousel__slide-link {
    margin: 25px auto 0;
    word-break: break-all;
}
.project-carousel__img-container {
    position: relative;
}
.project-carousel__img {
    width: 660px;
    height: 400px;
    object-fit: cover;
    border-radius: 15px;
}
.project-carousel__img-gradient {
    border: 1px solid var(--item-border);
    background: linear-gradient(rgba(0, 14, 26, 0.2) 30%, rgba(28, 28, 30));
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 28px;
}
.project-carousel__img-gradient:hover {
    cursor: pointer;
}
.project-carousel__img-gradient > div {
    text-align: left;
    color: white;
}
.project-carousel__img-gradient h3 {
    font-size: var(--font-size-xxxl);
    font-family: var(--header-font);
    margin-bottom: 12px;
}
@media (max-width: 900px) {
    .project-carousel__img {
        width: 460px;
        height: 300px;
    }
    .project-carousel__img-gradient h3 {
        font-size: var(--font-size-xxl);
        margin-bottom: 12px;
    }
}
@media (max-width: 675px) {
    .project-carousel__img {
        width: 375px;
        height: 300px;
    }
    .carousel__slide--next .project-carousel__slide-link,
    .carousel__slide--prev .project-carousel__slide-link {
        opacity: 0;
    }
}
@media (max-width: 550px) {
    .project-carousel__img {
        width: 300px;
        height: 300px;
    }
}

.project-carousel__share-button {
    background: var(--sea-gull);
    color: var(--background);
    width: 35px;
    height: 35px;
    border-radius: 17.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 35px;
}
.project-carousel__share-button:hover {
    cursor: pointer;
    filter: brightness(1.05);
}
.project-carousel__caption {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
    align-items: center;
}

/* slider css mutations */
.carousel__slide--active > .carousel__item {
    transform: scale(1) !important;
}
.carousel__slide--active {
    z-index: 1;
}
.carousel__slide--prev,
.carousel__slide--next {
    z-index: 0;
    opacity: 0.7;
}
.carousel__item {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}
.carousel__slide > .carousel__item {
    transform: scale(1);
    opacity: 0.5;
    transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
    opacity: 1;
    transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
    transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
    transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
    transform: scale(1.1);
}
</style>
