<template>
    <a
        class="no-animation"
        target="_blank"
        href="https://www.facebook.com/armin.novacek"
        ><i class="fab fa-facebook" :class="styleClass"></i
    ></a>
    <a
        class="no-animation"
        target="_blank"
        href="https://www.instagram.com/arminnova_/"
        ><i class="fab fa-instagram" :class="styleClass"></i
    ></a>
    <a
        class="no-animation"
        target="_blank"
        href="https://www.xing.com/profile/Armin_Novacek/cv"
        ><i class="fab fa-xing-square" :class="styleClass"></i
    ></a>
    <a
        class="no-animation"
        target="_blank"
        href="https://www.linkedin.com/in/armin-novacek-13633b198/"
        ><i class="fab fa-linkedin" :class="styleClass"></i
    ></a>
</template>

<script>
export default {
    props: {
        styleClass: {
            type: String,
            required: false,
            default: ""
        }
    }  
};
</script>

<style scoped>
.footer__socials {
    color: var(--disabled-light-gray);
    font-size: var(--font-size-xxxl);
}
.home__introduction--socials {
    color: var(--text-light);
    font-size: var(--font-size-xxl);
}
.fa-facebook:hover {
    color: #4267b2;
}
.fa-instagram:hover {
    background: -webkit-radial-gradient(
        33% 100% circle,
        #fed373 4%,
        #f15245 30%,
        #d92e7f 62%,
        #9b36b7 85%,
        #515ecf
    );
    background: radial-gradient(
        circle at 33% 100%,
        #fed373 4%,
        #f15245 30%,
        #d92e7f 62%,
        #9b36b7 85%,
        #515ecf
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fa-xing-square:hover {
    color: #01615d;
}
.fa-linkedin:hover {
    color: #0274b3;
}
</style>
