export const locales = {
    de: {
        home: {
            thatsMe: "Das bin ich.",
            goodMorning: "Guten Morgen",
            goodDay: "Guten Tag",
            goodEvening: "Guten Abend",
            helloNightOwl: "Hallo Nachtschwärmer",
            intro1: ", mein Name ist ",
            intro2: ". Ich bin studierter ",
            intro3: "Web- und Appentwickler",
            intro4: ", sowie passionierter Webdesigner.",
            skills: "Kompetenzen",
            developer: "Entwickler",
            webDevelopment: "Web Entwicklung",
            appDevelopment: "App Entwicklung",
            webDesign: "Webdesign",
            graphicDesign: "Grafikdesign",
            uxUiDesign: "UX/UI Design",
            myPortfolio: "Mein Portfolio",
            frontendDevelopment: "Frontend Development",
            atSdsAndPresse: "bei Styria Digital Services für „Die Presse“.",
            ofWebAndGraphic: "für Web und Grafik.",
            commitment: "Engagement",
            atWorkAndPrivate: "im Beruf, ausgewogen im Privatleben.",
            myCv: "Mein Lebenslauf",
            myProjects: "Meine Projekte",
            projectsIntro1: "In meiner Laufbahn als Developer habe ich bisher einige Projekte umsetzen dürfen.",
            projectsIntro2: "Hier ein kleiner Einblick in meine Kreationen.",
        },
        resume: {
            birthdate: "24. November 1997",
            location: "Wien, Österreich",
            yearsExperience: "Jahre Erfahrung",
            projectsDone: "Projekte abgeschlossen",
            hoursProgrammed: "Std. programmiert",
            plusLinesOfCode: "+ Zeilen Code",
            professionalExperience: "Berufserfahrung",
            jobProfile: "Jobprofil",
            education: "Ausbildung",
            studyContent: "Studieninhalt",
            programmingLanguages: "Program&shy;mier&shy;sprachen",
            languages: "Sprachen",
            downloadCv: "Lebenslauf herunterladen"
        },
        navigation: {
            cv: "Lebenslauf",
            imprint: "Impressum",
            language: "Sprache",
            colorTheme: "Farbschema",
            home: "Home",
            portfolio: "Portfolio",
            projectDetails: "Projektdetails",
            pageNotFound: "Seite nicht gefunden",
        },
        projects: {
            filterByTags: "Filtern nach Tags",
            removeAllTags: "Tags entfernen",
            removeSearchWord: "Suche zurücksetzen",
            readMore: "Mehr erfahren",
            shareLink: "Link teilen",
            shareLinkText: "Schau dir mal dieses Projekt an",
            didntSeeEnough: "Noch nicht genug gesehen?",
            hereIsTheWholePortfolio: "Hier geht es zum gesamten Portfolio",
            noSearchResultsFor: "Keine Suchergebnisse für",
            searchForProjects: "Suche nach Projekten...",
            search: "Suchen"
        },
        legal: {
            imprint: "Impressum",
            infoAccordingTo:
                "Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.",
            contactPerson: "Armin Novacek",
            address: "Schweidlgasse 19/26",
            location: "1020 Wien",
            country: "Österreich",
            euDisputeResolution: "EU-Streitschlichtung",
            odrInfo:
                "Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren. Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter http://ec.europa.eu/odr?tid=121890394 zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.",
            notObligatedToParticipate:
                "Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.",
            liabilityForContent: "Haftung für Inhalte dieser Website",
            contentDevelopment:
                "Wir entwickeln die Inhalte dieser Website ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Website übernehmen, speziell für jene, die seitens Dritter bereitgestellt wurden. Als Diensteanbieter sind wir nicht verpflichtet, die von ihnen übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.",
            removalAndBlockingOfInformation:
                "Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle unserer Nichtverantwortlichkeit davon unberührt.",
            reportProblematicContent:
                "Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.",
            liabilityForLinks: "Haftung für Links auf dieser Website",
            noLiabilityForLinkedWebsites:
                "Unsere Website enthält Links zu anderen Websites für deren Inhalt wir nicht verantwortlich sind. Haftung für verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.",
            reportProblematicLinks:
                "Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.",
            copyrightNotice: "Urheberrechtshinweis",
            copyrightInfo:
                "Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf anderen Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.",
            reportCopyrightViolation:
                "Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.",
            imageCredits: "Bildernachweis",
            imageCreditNotice: "Die Fotos auf dieser Webseite sind urheberrechtlich geschützt.",
            photographer: "Die Bilderrechte liegen bei dem folgenden Fotografen: Fotograf Armin Novacek",
            allTextsProtected: "Alle Texte sind urheberrechtlich geschützt.",
            source: "Quelle: Erstellt mit dem Impressum Generator von AdSimple für Österreich",
            useOfAIImagesFromCanva: "Verwendung AI-generierter Bilder von Canva",
            canvaInfo:
                "Diese Seite zeigt Bilder, die von Canva bereitgestellt werden. Canva ist ein Online-Tool zur Erstellung von Grafiken und Designs, das auch AI generierte Bilder anbietet. Wir haben sorgfältig ausgewählt, welche Bilder wir verwenden, um sicherzustellen, dass Sie unseren Inhalt angemessen repräsentieren und unsere Website visuell ansprechend machen.",
            googleWebFonts: "Google Web Fonts",
            webFontsInfo:
                "Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Die Google Fonts sind lokal installiert. Eine Verbindung zu Servern von Google findet dabei nicht statt.",
        },
        pageNotFound: {
            pageNotFound: "Seite nicht gefunden",
            backToHome: "Zurück zur Startseite",
            notification: "Leider konnte die Seite nicht gefunden werden. Bitte suchen Sie unter einer anderen URL oder kehren Sie zur Startseite zurück."
        }
    },
    en: {
        home: {
            thatsMe: "That is me.",
            goodMorning: "Good morning",
            goodDay: "Good day",
            goodEvening: "Good evening",
            helloNightOwl: "Hello night owl",
            intro:
                "<span style='font-weight: bold;'>Armin Novacek</span>. I am a studied <span style='font-weight: bold;'>web and app developer</span>, as well as a passionate web designer.",
            intro1: ", my name is ",
            intro2: ". I am a studied ",
            intro3: "web and app developer",
            intro4: ", as well as passionate web designer.",
            skills: "Skills",
            developer: "Developer",
            webDevelopment: "Web development",
            appDevelopment: "App development",
            webDesign: "Web design",
            graphicDesign: "Graphic design",
            uxUiDesign: "UX/UI design",
            myPortfolio: "My portfolio",
            frontendDevelopment: "Frontend development",
            atSdsAndPresse: "at Styria Digital Services for „Die Presse“.",
            ofWebAndGraphic: "of web und graphic.",
            commitment: "Commitment",
            atWorkAndPrivate: "at work, balanced in private life.",
            myCv: "My CV",
            myProjects: "My projects",
            projectsIntro1: "In my career as a developer I've been able to implement a few projects.",
            projectsIntro2: "Here's a little insight into my creations.",
        },
        resume: {
            birthdate: "Nov. 24, 1997",
            location: "Vienna, Austria",
            yearsExperience: "years experience",
            projectsDone: "projects done",
            hoursProgrammed: "hours coded",
            plusLinesOfCode: "+ lines of code",
            professionalExperience: "Professional experience",
            jobProfile: "job profile",
            education: "Education",
            studyContent: "Study content",
            programmingLanguages: "Programming languages",
            languages: "Languages",
            downloadCv: "Download CV"
        },
        navigation: {
            cv: "CV",
            imprint: "Imprint",
            language: "Language",
            colorTheme: "Color theme",
            home: "Home",
            portfolio: "Portfolio",
            projectDetails: "Project details",
            pageNotFound: "Page not found",
        },
        projects: {
            filterByTags: "Filter by tags",
            removeAllTags: "Remove tags",
            removeSearchWord: "Reset search",
            readMore: "Read more",
            shareLink: "Share link",
            shareLinkText: "Take a look at this project",
            didntSeeEnough: "Haven't seen enough yet?",
            hereIsTheWholePortfolio: "Here is the entire portfolio",
            noSearchResultsFor: "No search results for",
            searchForProjects: "Search for projects...",
            search: "Search"
        },
        legal: {
            imprint: "Imprint",
            infoAccordingTo:
                "Information obligation according to §5 E-Commerce Act, §14 Commercial Code, §63 Trade Regulation and disclosure obligation according to §25 Media Act.",
            contactPerson: "Armin Novacek",
            address: "Schweidlgasse 19/26",
            location: "1020 Vienna",
            country: "Austria",
            euDisputeResolution: "EU Dispute Resolution",
            odrInfo:
                "According to the Regulation on Online Dispute Resolution for Consumer Affairs (ODR Regulation), we would like to inform you about the Online Dispute Resolution Platform (ODR Platform). Consumers have the opportunity to submit complaints to the Online Dispute Resolution Platform of the European Commission at http://ec.europa.eu/odr?tid=121890394. You can find the necessary contact details above in our imprint.",
            notObligatedToParticipate:
                "However, we would like to point out that we are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.",
            liabilityForContent: "Liability for the content of this website",
            contentDevelopment:
                "We are constantly developing the content of this website and strive to provide correct and up-to-date information. Unfortunately, we cannot assume any liability for the correctness of all content on this website, especially for content provided by third parties. As a service provider, we are not obligated to monitor transmitted or stored information or to investigate circumstances that indicate illegal activity.",
            removalAndBlockingOfInformation:
                "Our obligations to remove or block the use of information under general laws as a result of court or official orders remain unaffected even in the event of our non-responsibility.",
            reportProblematicContent:
                "If you notice problematic or illegal content, please contact us immediately so that we can remove the illegal content. You can find the contact details in the imprint.",
            liabilityForLinks: "Liability for links on this website",
            noLiabilityForLinkedWebsites:
                "Our website contains links to other websites for whose content we are not responsible. Liability for linked websites does not exist for us because we had and have no knowledge of illegal activities, and we have not noticed any such illegalities so far, and we would remove links immediately if we became aware of any illegalities.",
            reportProblematicLinks:
                "If you notice illegal links on our website, please contact us. You can find the contact details in the imprint.",
            copyrightNotice: "Copyright notice",
            copyrightInfo:
                "All content on this website (images, photos, texts, videos) is protected by copyright. Please ask us before you distribute, reproduce, or exploit the content of this website, such as republishing it on other websites. If necessary, we will legally pursue the unauthorized use of parts of the content of our site.",
            reportCopyrightViolation: "If you find content on this website that violates copyright, please contact us.",
            imageCredits: "Image credits",
            imageCreditNotice: "The photos on this website are protected by copyright.",
            photographer: "The image rights belong to the following photographer: Photographer Armin Novacek",
            allTextsProtected: "All texts are protected by copyright.",
            source: "Source: Created with the Impressum Generator from AdSimple (translated from german to english)",
            useOfAIImagesFromCanva: "Use of AI-generated images from Canva",
            canvaInfo:
                "This page displays images provided by Canva. Canva is an online tool for creating graphics and designs, which also offers AI-generated images. We have carefully selected which images to use to ensure they appropriately represent our content and make our website visually appealing.",
            googleWebFonts: "Google Web Fonts",
            webFontsInfo:
                "This page uses so-called web fonts provided by Google to ensure a uniform display of fonts. The Google Fonts are installed locally. There is no connection to servers of Google during this process.",
        },
        pageNotFound: {
            pageNotFound: "Page not found",
            backToHome: "Back to home page",
            notification: "Unfortunately the page could not be found. Please search a different URL or return to the home page."
        }
    },
};
