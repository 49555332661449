<template>
    <div class="labeled-item">
        <h2 class="labeled-item__label" v-html="label" />
        <div class="progress-bar__item" v-for="(c, index) of content" :key="index">
            <progress class="progress-bar" max="100" :value="c.score" />
            <p class="progress-bar__label">{{ c.name }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ResumeLevelItem",
    props: {
        label: {
            type: String,
            required: true,
        },
        content: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>
.progress-bar__item {
    padding-bottom: 10px;
}
.progress-bar {
    width: 100%;
    height: 10px;
    display: block;
    border-radius: 50px;
    margin-top: 10px;
    margin-bottom: 5px;
    -webkit-appearance: none;
    border: none;
}
progress::-webkit-progress-bar {
    background: black;
    border-radius: 50px;
    padding: 2px;
}
progress::-webkit-progress-value {
    border-radius: 50px;
    background: rgb(201, 201, 201);
}
progress::-moz-progress-bar {
    background-color: gray;
    border-radius: 50px;
}
.progress-bar__label {
    margin: 0;
    margin-bottom: 5px;
}
</style>
