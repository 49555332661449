<template>
    <div v-if="project">
        <div class="project__header">
            <h1 class="project__header--title" v-html="project.title"></h1>
            <share-project-link :project="project" :project-id="project.id.toString()" />
        </div>
        <div v-if="showCarousel" class="project__carousel">
            <Carousel ref="carousel">
                <template #slides>
                    <Slide v-for="(img_name, index) in project.img_names" :key="index">
                        <div class="carousel__item no-markup-text">
                            <img
                                class="project__image"
                                :src="getImgPath('projects', img_name)"
                                :alt="project.title"
                                draggable="false"
                            />
                            <GradientAccordion
                                :default-open="isFilterAccordionOpen(project.img_captions[index])"
                                additional-style-classes="gradient--full-width"
                                :ref="'gradientAccordion_' + index"
                            >
                                <p>{{ project.img_captions[index] }}</p>
                            </GradientAccordion>
                        </div>
                    </Slide>
                </template>
                <template #addons>
                    <navigation />
                    <pagination />
                </template>
            </Carousel>
        </div>
        <div v-else>
            <img
                :src="getImgPath('projects', project.img_names[0])"
                :alt="project.title"
                class="project__image"
                draggable="false"
            />
            <GradientAccordion
                :default-open="isFilterAccordionOpen(project.img_captions[0])"
                additional-style-classes="gradient--full-width"
                :ref="'gradientAccordion_' + index"
            >
                <p>{{ project.img_captions[0] }}</p>
            </GradientAccordion>
        </div>
        <ios-expand-icon class="project__expand-icon" w="20px" h="20px" @click="showImageViewer(project)" />
        <Badges :badges="project.tools" />
        <p v-html="project.description"></p>
        <a v-if="project.project_raw_link && project.project_link" :href="project.project_raw_link" target="_blank">{{
            project.project_link
        }}</a>
        <p class="project__projects-teaser">
            {{ $t("projects.didntSeeEnough") }}<br />
            <router-link to="/portfolio">{{ $t("projects.hereIsTheWholePortfolio") }}</router-link>
        </p>
        <div class="project__pagination">
            <router-link :to="`/portfolio/${previous}`" class="no-animation" @click="init(previous)">
                <ios-arrow-back-icon v-if="project.id != 1" w="18px" h="18px" />
            </router-link>
            <p>{{ project.id }} / {{ parseInt(projectsLength) + 1 }}</p>
            <router-link :to="`/portfolio/${next}`" class="no-animation" @click="init(next)">
                <ios-arrow-forward-icon v-if="project.id != projectsLength + 1" w="18px" h="18px" />
            </router-link>
        </div>
    </div>
</template>

<script>
import projectsDe from "../assets/texts/projects_de.json";
import projectsEn from "../assets/texts/projects_en.json";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import ShareProjectLink from "../components/ShareProjectLink.vue";
import Badges from "../components/Badges.vue";
import IosArrowBackIcon from "vue-ionicons/dist/ios-arrow-back.vue";
import IosArrowForwardIcon from "vue-ionicons/dist/ios-arrow-forward.vue";
import GradientAccordion from "../components/GradientAccordion.vue";
import IosExpandIcon from "vue-ionicons/dist/ios-expand.vue";

export default {
    name: "ProjectDetails",
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        ShareProjectLink,
        Badges,
        IosArrowBackIcon,
        IosArrowForwardIcon,
        GradientAccordion,
        IosExpandIcon,
    },
    computed: {
        projectsLength() {
            return this.projects.length - 1;
        },
        next() {
            return this.project.id + 1;
        },
        previous() {
            return this.project.id - 1;
        },
        lang() {
            return this.$store.state.lang;
        },
    },
    data() {
        return {
            project: null,
            showCarousel: false,
            projects: [],
        };
    },
    emits: ["image-viewer-visible"],
    watch: {
        lang(selected) {
            this.projects = selected === "de" ? projectsDe : projectsEn;
            this.init();
        },
    },
    mounted() {
        this.projects = this.lang === "de" ? projectsDe : projectsEn;
        this.init();
        // initialize the navigation handler
        this.initializeNavHandlers();
    },
    methods: {
        init(projectId = null) {
            let id = 1;
            if (projectId === null) {
                id = this.$route.params.id;
            } else {
                id = projectId;
                this.$refs.carousel.data.currentSlide.value = 0;
            }
            this.project = this.projects.find((project) => project.id == id);
            if (!this.project) {
                return;
            }
            this.showCarousel = this.project.img_names.length > 1;
        },
        isFilterAccordionOpen(words) {
            return words.length < 250;
        },
        closeAccordion(direction) {
            const currentSlide = this.$refs.carousel.data.currentSlide.value;
            const lastSlide = direction === "next" ? currentSlide - 1 : currentSlide + 1;
            const prop = "gradientAccordion_" + lastSlide;
            const referredProp = this.$refs[prop];

            if (referredProp) {
                referredProp.closeAccordion();
            }
        },
        initializeNavHandlers() {
            window.closeAccordion = this.closeAccordion;
            window.onload = () => {
                const nextNav = document.querySelector(".carousel__next");
                const prevNav = document.querySelector(".carousel__prev");
                if (nextNav && prevNav) {
                    nextNav.onclick = function() {
                        window.closeAccordion("next");
                    };
                    prevNav.onclick = function() {
                        window.closeAccordion("prev");
                    };
                }
            };
        },
        showImageViewer(project) {
            const imageSources = {
                img_names: project.img_names,
                img_captions: project.img_captions,
            };
            const currentSlide = this.$refs.carousel.data.currentSlide.value;
            this.$emit("image-viewer-visible", true, imageSources, currentSlide);
        },
    },
};
</script>

<style scoped>
.project__header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 50px;
    align-items: baseline;
}
.project__header--title {
    margin: 4px 0 0;;
}
.project__expand-icon {
    position: absolute;
    margin-top: -40px;
    background: var(--sea-gull);
    color: var(--background);
    padding: 5px;
    border-radius: 15px;
}
.project__expand-icon:hover {
    filter: brightness(1.05);
    cursor: pointer;
}
.project__image {
    object-fit: contain;
    max-height: 350px;
    border-radius: 15px;
    width: 100%;
}
.project__projects-teaser {
    margin-top: 50px;
    color: var(--text-dark);
}
.project__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}
.project__pagination p {
    margin: 30px 60px;
}
.project__pagination div {
    background-color: var(--sea-gull);
    border-radius: 15px;
    width: 30px;
    height: 30px;
    color: var(--background);
    align-items: center;
    justify-content: center;
}
.project__pagination a:hover {
    color: var(--text-dark);
}
@media (max-width: 600px) {
    .project__header {
        flex-direction: column;
    }
    .project__header--title {
        margin-bottom: 10px;
    }
}
</style>
