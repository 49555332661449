<template>
    <div class="image-viewer">
        <div class="image-viewer__content">
            <ios-close-icon
                w="40px"
                h="40px"
                @click="close"
                class="image-viewer__close-button image-viewer__navigation"
            />
            <img
                class="image-viewer__image"
                :src="getImgPath('projects', sources.img_names[activeIndex])"
                draggable="false"
            />
            <div class="image-viewer__footer">
                <p class="image-viewer__footer--caption" v-html="getShortCaption(sources.img_captions[activeIndex])" />
                <div>
                    <ios-arrow-round-back-icon
                        disabled="true"
                        w="40px"
                        h="40px"
                        class="image-viewer__navigation"
                        :class="{ 'image-viewer__navigation--disabled': startOfSlides }"
                        @click="changeSlide('back')"
                    />
                    <ios-arrow-round-forward-icon
                        w="40px"
                        h="40px"
                        class="image-viewer__navigation"
                        :class="{ 'image-viewer__navigation--disabled': endOfSlides }"
                        @click="changeSlide('forward')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IosCloseIcon from "vue-ionicons/dist/ios-close.vue";
import IosArrowRoundForwardIcon from "vue-ionicons/dist/ios-arrow-round-forward.vue";
import IosArrowRoundBackIcon from "vue-ionicons/dist/ios-arrow-round-back.vue";
export default {
    name: "ImageViewer",
    components: {
        IosCloseIcon,
        IosArrowRoundForwardIcon,
        IosArrowRoundBackIcon,
    },
    props: {
        sources: {
            type: Object,
            required: true,
        },
        startSlide: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    emits: ["image-viewer-visible"],
    data() {
        return {
            activeIndex: this.startSlide,
            captionLength: 80,
        };
    },
    computed: {
        startOfSlides() {
            return this.activeIndex === 0;
        },
        endOfSlides() {
            return this.activeIndex === this.sources.img_names.length - 1;
        },
    },
    methods: {
        close() {
            this.$emit("image-viewer-visible", false);
        },
        changeSlide(direction) {
            if ((direction === "back" && this.startOfSlides) || (direction === "forward" && this.endOfSlides)) {
                return;
            }
            direction === "back" ? this.activeIndex-- : this.activeIndex++;
        },
        getShortCaption(caption) {
            if (caption.length <= this.captionLength) {
                return caption;
            }
            return caption.substr(0, this.captionLength) + "\u2026";
        },
    },
};
</script>

<style scoped>
.image-viewer {
    position: fixed;
    background: var(--background-dark);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 15;
}
.image-viewer__content {
    margin: auto;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
    overflow-x: hidden;
}
.image-viewer__close-button {
    display: block;
    cursor: pointer;
    text-align: flex-end;
    padding-top: 12px;
}
.image-viewer__image {
    max-height: 70%;
    max-width: 100%;
    margin: auto;
}
.image-viewer__footer {
    display: flex;
    justify-content: space-between;
}
.image-viewer__footer--caption {
    width: 60%;
    padding-left: 5px;
}
.image-viewer__navigation:hover {
    color: var(--text-dark);
    cursor: pointer;
}
.image-viewer__navigation--disabled {
    color: var(--text-dark);
    cursor: initial;
}
</style>
