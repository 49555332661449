<template>
    <div class="settings">
        <ios-settings-icon w="25px" h="25px" class="settings__icon" :class="{'settings__icon--rotated': showSettings}" @click="toggleSettings" />
        <transition name="fade">
            <div v-if="showSettings" class="settings__content" v-click-outside="() => showSettings = false">
                <div class="settings__popper"></div>
                <div class="settings__inner-content">
                    <div class="settings__inner-content--alignment">
                        <span class="settings__inner-content--text-color"
                            >{{ $t("navigation.language") }}:</span
                        >
                        <div>
                            <a
                                @click="changeLang('de')"
                                :class="{
                                    'router-link-active': langIsGerman,
                                    'header-menu--lang': !langIsGerman,
                                }"
                                >de</a
                            >
                            /
                            <a
                                @click="changeLang('en')"
                                :class="{
                                    'router-link-active': !langIsGerman,
                                    'header-menu--lang': langIsGerman,
                                }"
                                >en</a
                            >
                        </div>
                    </div>
                    <div class="settings__inner-content--alignment">
                        <span class="settings__inner-content--text-color"
                            >{{ $t("navigation.colorTheme") }}:</span
                        >
                        <Switch @change="onToggleSwitch" :checked="$store.state.lightTheme">
                            <ios-sunny-icon w="20px" h="20px" class="switch-icon" />
                            <ios-moon-icon w="20px" h="20px" class="switch-icon" />
                        </Switch>
                    </div>
                </div>
            </div>
        </transition>
    </div>    
</template>

<script>
import Switch from "./Switch.vue";
import IosSunnyIcon from "vue-ionicons/dist/ios-sunny.vue";
import IosMoonIcon from "vue-ionicons/dist/ios-moon.vue";
import IosSettingsIcon from "vue-ionicons/dist/ios-settings.vue";
export default {
    name: "Settings",
    components: {
        Switch,
        IosSunnyIcon,
        IosMoonIcon,
        IosSettingsIcon,
    },
    data () {
        return {
            lightTheme: false,
            showSettings: false,
        }
    },
    computed: {
        langIsGerman() {
            return this.$store.state.lang === "de";
        },
    },
    methods: {
        onToggleSwitch() {
            this.$store.commit("changeColorTheme");
        },
        changeLang(switchTo) {
            if (switchTo === this.$store.state.lang) {
                return;
            }
            this.$root.$i18n.locale = switchTo;
            this.$store.commit("changeLang");
        },
        toggleSettings() {
            this.showSettings = !this.showSettings;
            // freeze the header if settings are shown
            this.$emit('headroom-behavior', this.showSettings);
        },
    }
}
</script>

<style scoped>
.switch-icon {
    color: var(--golden-shower);
    margin-left: 3px;
}
.header-menu--lang:hover {
    cursor: pointer;
}
.settings__content {
    background-color: var(--item-background);
    border: 1px solid var(--item-border);
    border-radius: 15px;
    color: #fff;
    margin-top: 8px;
    width: 200px;
    margin-left: -200px;
    padding: 12px 20px;
    position: absolute;
}
.settings__inner-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.settings__inner-content--alignment {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.settings__inner-content--text-color {
    color: var(--text-light);
}
.settings__popper {
    border-bottom: 8px solid var(--item-border);
    border-left: 8px solid #0000;
    border-right: 8px solid #0000;
    position: absolute;
    right: 20px;
    top: -8px;
}
.settings__icon:hover {
    color: var(--text-dark);
    cursor: pointer;
}
.settings__icon {
    transition: all .5s ease;
}
.settings__icon--rotated {
    transform: rotate(67.5deg);
}
/* transition settings background */
.fade-enter-active,
.fade-leave-active {
    transition: all 0.2s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>