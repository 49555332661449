<template>
    <Header />
    <div class="main">
        <div class="main__inner">
            <!-- animations when switching routes -->
            <router-view v-slot="{ Component }" @image-viewer-visible="setImageViewerVisibility">
                <transition :name="getRouterAnimation()" mode="out-in">
                    <component :is="Component"></component>
                </transition>
            </router-view>
        </div>
    </div>
    <Footer />
    <Flyout />
    <ImageViewer
        v-if="imageViewer.show"
        :sources="imageViewer.sources"
        :start-slide="imageViewer.startSlide"
        @image-viewer-visible="setImageViewerVisibility"
    />
    <notifications position="bottom" />
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Flyout from "./components/Flyout.vue";
import ImageViewer from "./components/ImageViewer.vue";

export default {
    name: "App",
    components: {
        Header,
        Footer,
        Flyout,
        ImageViewer,
    },
    watch: {
        $route(to, from) {
            this.currentRouterId = to.meta.id;
            this.lastRouterId = from.meta.id;
        },
    },
    data() {
        return {
            currentRouterId: 0,
            lastRouterId: 0,
            imageViewer: {
                show: false,
                sources: {},
                startSlide: 0,
            },
        };
    },
    methods: {
        getRouterAnimation() {
            // no animation on initial page load or none header-linked routes
            if (!this.lastRouterId || !this.currentRouterId) return "none";
            return this.currentRouterId < this.lastRouterId ? "route-left" : "route-right";
        },
        setImageViewerVisibility(visibleStatus, sources = null, startSlide = 0) {
            this.imageViewer.startSlide = startSlide;
            this.imageViewer.sources = sources;
            this.imageViewer.show = visibleStatus;
        },
    },
};
</script>

<style>
#app {
    font-family: var(--main-font);
}
h1 {
    font-family: var(--header-font);
    letter-spacing: 0.8px;
}
h2 {
    font-family: var(--header-font);
    letter-spacing: 1px;
}
body {
    font-size: var(--font-size-medium);
    color: var(--text-light);
    margin: 0;
    overflow: overlay;
    overflow-x: hidden;
}
/* content */
.main {
    background-color: var(--background);
    color: var(--text-light);
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    margin-bottom: 300px;
    padding: 110px 0;
}
.main__inner {
    overflow-y: hidden;
    padding: 0 5% 0;
    overflow-x: hidden;
    max-width: 900px;
    margin: auto;
}
/* breakpoints main content */
@media (min-width: 500px) {
    .main__inner {
        padding: 0 10% 0;
    }
}
@media (min-width: 500px) {
    .main__inner {
        padding: 0 10% 0;
    }
}
/* link and router */
.no-animation {
    text-decoration: none;
    color: var(--text-light);
}
a:not(.no-animation) {
    position: relative;
    color: var(--text-light);
    text-decoration: none;
    padding-bottom: 7px;
}

a:not(.no-animation):hover::before {
    transform: scaleX(1);
}
a:not(.no-animation)::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--text-light);
    transform: scaleX(0);
    transform-origin: top right;
    transition: transform 0.3s ease;
}
.router-link-active:before {
    transform: initial !important;
}

/* route transition animation */
.route-right-enter-from,
.route-left-leave-to {
    opacity: 0;
    transform: translateX(100px);
}
.route-right-enter-active,
.route-left-enter-active {
    transition: all 0.3s ease-out;
}
.route-right-leave-to,
.route-left-enter-from {
    opacity: 0;
    transform: translateX(-100px);
}
.route-right-leave-active,
.route-left-leave-active {
    transition: all 0.3s ease-in;
}

/* text markup */
.no-markup-text {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                      supported by Chrome, Edge, Opera and Firefox */
}
.unbutton {
    background: transparent !important;
}
.unbutton:hover {
    color: var(--text-light) !important;
    border-color: var(--text-light) !important;
}
/* form */
.form {
    display: grid;
    grid-gap: 50px;
}
.form.form--1-1 {
    grid-template-columns: 1fr 1fr;
}
@media (max-width: 800px) {
    .form {
        grid-gap: 0;
    }
    .form.form--1-1 {
        grid-template-columns: 1fr;
    }
}
/* hide mobile */
@media (max-width: 500px) {
    .hide--mobile {
        display: none;
    }
}
</style>
